<template>
  <b-container class="import-wizard-step import-wizard-step01">
    <b-row no-gutters>

      <b-col cols="4" class="sidebar-text text-left py-5 px-5" align-self="start">
        <h2 class="text-dark">{{ $t(translationPath + 'intro_title') }}</h2>
        <div v-html="$t('transactions.import.import-spreadsheet-advanced-wizard.common.step1_description')"></div>
      </b-col>

      <b-col cols="8" class="detail-form text-left py-5 px-5" align-self="center">
        <b-form-group
          label-class="pr-4 text-black"
          label-for="file"
          label-cols-lg="3"
          label-align-lg="left"
          required
          class="pt-4"
          :invalid-feedback="invalidFileFeedback"
        >
          <template slot="label">{{ $t(translationPath + 'file_label') }}</template>
          <template slot="description">{{ $t(translationPath + 'file_description') }}</template>
          <b-form-file id="file"
            class="form-control"
            :placeholder="$t(translationPath + 'file_placeholder')"
            :drop-placeholder="$t(translationPath + 'file_drop_placeholder')"
            :state="$v.file.$dirty ? !$v.file.$error : null"
            v-model="file"
            @input="$v.file.$touch()"
          ></b-form-file>
        </b-form-group>

        <b-form-group
          label-class="pr-4 text-black"
          label-for="text-data"
          label-cols-lg="3"
          label-align-lg="left"
          required
          class="pt-4"
          :invalid-feedback="invalidTextDataFeedback"
        >
          <template slot="label">{{ $t(translationPath + 'text_data_label') }}</template>
          <template slot="description">{{ $t(translationPath + 'text_data_description') }}</template>
          <b-form-textarea
            v-model="textData"
            id="text-data"
            size="lg"
            required
            rows="30"
            max-rows="30"
            :state="$v.textData.$dirty ? !$v.textData.$error : null"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { requiredUnless, requiredIf } from 'vuelidate/lib/validators'
import axios from 'axios'
import ImportAccountStatementSpreadsheetAdvancedMixin from './ImportAccountStatementSpreadsheetAdvancedMixin'

const isFileOk = getter => function () {
  return this.isFileOk
}
const isFileTypeOk = getter => function () {
  return this.isFileTypeOk
}
const isFileStructOk = getter => function () {
  return this.isFileStructOk
}
const containAtLeastOneRow = getter => function () {
  if (!this.textData) {
    return true
  }

  const re = /.+\t.+\t.+\t.+\t.+/ // 4 tab column separators, 5 columns with data
  return re.test(this.textData)
}

const isTextDataParsedOk = getter => function () {
  return !this.textDataParseError
}

export default {
  name: 'ImportAccountStatementSpreadsheetAdvancedWizardStep01',
  mixins: [ImportAccountStatementSpreadsheetAdvancedMixin],
  data () {
    return {
      translationPath: 'transactions.import.import-spreadsheet-wizard.step1.',
      translationPath2: 'transactions.import.import-spreadsheet-wizard.step2.',
      file: null,
      isFileOk: true,
      isFileTypeOk: true,
      isFileStructOk: true,
      textData: null,
      textDataParseError: false,
      form_account_data: {}
    }
  },
  validations: {
    file: {
      required: requiredUnless('textData'),
      fileOk: isFileOk(),
      fileTypeOk: isFileTypeOk(),
      fileStructOk: isFileStructOk()
    },
    textData: {
      required: requiredIf(model => !model.file && model.textData),
      containAtLeastOneRow: containAtLeastOneRow(),
      isTextDataParsedOk: isTextDataParsedOk()
    },
    form: ['file', 'textData']
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    invalidFileFeedback () {
      if (this.$v.file.required === false) return this.$t(this.translationPath + 'errors.file_required')
      if (this.$v.file.fileTypeOk === false) return this.$t(this.translationPath + 'errors.file_invalid_type')
      if (this.$v.file.fileStructOk === false) return this.$t(this.translationPath + 'errors.file_invalid_struct')
      if (this.$v.file.fileOk === false) return this.$t(this.translationPath + 'errors.file_unknown_error')
      return ''
    },
    invalidTextDataFeedback () {
      if (this.$v.textData.required === false) return this.$t(this.translationPath + 'errors.text_data_required')
      if (this.$v.textData.containAtLeastOneRow === false) return this.$t(this.translationPath + 'errors.text_data_requires_at_least_one_row')
      if (this.$v.textData.isTextDataParsedOk === false) return this.$t(this.translationPath + 'errors.text_data_parse_error')
      return ''
    }
  },
  methods: {
    async validate () {
      this.$v.form.$touch()
      let isValid = !this.$v.form.$invalid

      if (isValid && this.file && !this.textData) {
        const formData = new FormData()
        formData.append('file', this.file)
        const config = {
          baseURL: process.env.VUE_APP_ROOT_API
        }
        const UploadFileInstance = axios.create(config)
        UploadFileInstance.defaults.headers.common['Content-Type'] = 'multipart/form-data'
        await UploadFileInstance.post('/transactions/import-spreadsheet/upload', formData)
          .then((response) => {
            const responseModel = {
              fileName: response.data.data.fileName,
              columnHeaders: response.data.data.columnHeaders
            }
            this.$emit('validate-success', responseModel)
          })
          .catch((error) => {
            console.error(error)
            switch (error.response.data.error.code) {
              case 1:
                this.isFileTypeOk = false
                break
              case 2:
                this.isFileStructOk = false
                break
              default:
                this.isFileOk = false
                break
            }
            isValid = false
          })
      } else if (isValid && this.textData) {
        this.$bvToast.toast(this.$t('transactions.import.import_wizard_v2.step2.toast.trying_to_parse_description'), {
          title: this.$t('transactions.import.import_wizard_v2.step2.toast.trying_to_parse_title'),
          variant: 'info',
          solid: true
        })

        const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/transactions/import-spreadsheet-advanced/parse-text'
        const postData = { data: this.textData }
        const textDataApiResponse = await axios.post(sApiEndpoint, postData)
          .catch(err => {
            console.error(err)
          })

        if (!textDataApiResponse) {
          isValid = false
          this.textDataParseError = true
        } else {
          isValid = await this.prepareParseResponse(textDataApiResponse.data.data)
        }
      }

      return isValid
    }
  },
  watch: {
    file () {
      this.isFileOk = true
      this.isFileTypeOk = true
      this.isFileStructOk = true
    },
    textData () {
      this.textDataParseError = false
    }
  }
}
</script>

<style lang="scss">

@import '@/assets/scss/components/spirecta-table-where-row-is-selected.scss';
</style>
